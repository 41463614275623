export default [

  {
    header: 'Modules',
  },
  {
    title: 'navigation.assets',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    children: [
        {
        title: 'navigation.physicalassets',
        icon: 'HomeIcon',
        route: 'physicalassets-realestate',
      },
      {
        title: 'navigation.financialassets',
        icon: 'ZapIcon',
        route: 'financialassets-cash',
      },
    ],
  },
  {
    title: 'navigation.liabilities',
    icon: 'CreditCardIcon',
    route: 'liabilities-loan',
  },
//   {
//     title: 'navigation.finance',
//     icon: 'ZapIcon',
//     route: 'finance-bank',
//   },
  {
    title: 'navigation.generic',
    icon: 'ToolIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'navigation.utilities',
            icon: 'TvIcon',
            route: 'utilities-water',
          },
          {
            title: 'navigation.leisure',
            icon: 'YoutubeIcon',
            route: 'leisure-subscriptions',
          },
        {
        title: 'navigation.assistance',
        icon: 'PhoneIcon',
        route: 'assistance-importantcontacts',
      },
    ],
  },
  {
    title: 'navigation.insurance',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'navigation.life',
            icon: 'HeartIcon',
            route: 'insurance-life',
          },
          {
            title: 'navigation.general',
            icon: 'HomeIcon',
            route: 'insurance-general',
          },
    ],
  },
  {
    title: 'navigation.health',
    icon: 'HeartIcon',
    route: 'health-insurance',
  },
  {
    title: 'navigation.children',
    icon: 'UsersIcon',
    route: 'children-school',
  },

  {
    title: 'navigation.cashflow',
    icon: 'BriefcaseIcon',
    route: 'cashflow-transaction',
  },
//   {
//     title: 'navigation.cashflow',
//     icon: 'BriefcaseIcon',
//     tagVariant: 'light-warning',
//     children: [
//         {
//         title: 'navigation.transaction',
//         icon: 'BriefcaseIcon',
//         route: 'transaction',
//       },
//       {
//         title: 'navigation.transfer',
//         icon: 'ZapIcon',
//         route: 'transfer',
//       },
//     ],
//   },


//   {
//     title: 'navigation.investment',
//     icon: 'BriefcaseIcon',
//     route: 'investment-retirals',
//   },
  {
    title: 'navigation.budget',
    icon: 'ZapIcon',
    route: 'budget',
  },
//   {
//     title: 'navigation.insurance',
//     icon: 'HomeIcon',
//     route: 'insurance-life',
//   },


//   {
//     title: 'navigation.documents',
//     icon: 'ArchiveIcon',
//     route: 'documents',
//   },

//   {
//     title: 'navigation.documentsfolders',
//     icon: 'GridIcon',
//     route: 'documentsfolder',
//   },

  // {
  //   header: 'navigation.reports',
  // },
  {
    title: 'navigation.documentsvault',
    icon: 'GridIcon',
    route: 'documentsvault',
  },
  {
    title: 'navigation.reports',
    icon: 'MonitorIcon',
    route: 'reports',
  },

// {
//   title: 'navigation.documents',
//   icon: 'GridIcon',
//   route: 'documents-will',
// },


  // {
  //   title: 'navigation.importexp',
  //   icon: 'ShieldIcon',
  //   route: 'importexport',
  // },
]
