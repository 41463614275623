<template>
  <div>
    <div>
      <b-button
        v-for="(item, index) in list"
        :to="{ name: 'utilities-' + item.name }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="data[1] == item.name ? 'primary' : 'flat-primary'"
        :key="'utilitiestitle' + index"
      >
        {{ $t(item.title) }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  watch: {
    $route(to, from) {
      this.data = this.$route.name.split("-");
    },
  },

  data() {
    return {
      data: this.$route.name.split("-"),
      list: [
        {
          title: "navigation.water",
          name: "water",
        },
        {
          title: "navigation.electricity",
          name: "electricity",
        },
        {
          title: "navigation.cabletvdth",
          name: "cabletvdth",
        },
        {
          title: "navigation.mobile",
          name: "mobile",
        },
        {
          title: "navigation.landbroad",
          name: "landbroad",
        },
        {
          title: "navigation.gas",
          name: "gas",
        },
        // {
        //   title: "all",
        //   name: "all",
        // },
      ],
    };
  },
};
</script>
