<template>
  <div>
    <div>
      <b-button
        v-for="(item, index) in list"
        :to="{ name: 'assistance-' + item.name }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="data[1] == item.name ? 'primary' : 'flat-primary'"
        :key="'assistancetitle' + index"
      >
        {{ $t(item.title) }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  watch: {
    $route(to, from) {
      this.data = this.$route.name.split("-");
    },
  },

  data() {
    return {
      data: this.$route.name.split("-"),
      list: [
        {
          title: "navigation.importantcontacts",
          name: "importantcontacts",
        },
        {
          title: "navigation.supportstaff",
          name: "supportstaff",
        },
        // {
        //   title: "navigation.helplinenumber",
        //   name: "helplinenumber",
        // },


      ],
    };
  },
};
</script>
