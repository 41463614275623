<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <b-col class="content-header-left mb-1" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t($route.meta.pageTitle) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- <b-col class="content-header-left mb-1" cols="12" md="12">
      <settings-application v-if="routename[0]=='settings'&&routename[1]=='application'" :data="routename"></settings-application>
      {{ routename }}
    </b-col> -->

    <b-col class="content-header-left mb-1 invoice-actions" cols="12" md="12">
      <finance v-if="routename[0] == 'financialassets'" :data="routename"></finance>
      <!-- <investment
        v-else-if="routename[0] == 'investment'"
        :data="routename"
      ></investment> -->
      <insurance
        v-else-if="routename[0] == 'insurance'"
        :data="routename"
      ></insurance>
      <assets v-else-if="routename[0] == 'physicalassets'" :data="routename"></assets>
      <assistance
        v-else-if="routename[0] == 'assistance'"
        :data="routename"
      ></assistance>
      <leisure
        v-else-if="routename[0] == 'leisure'"
        :data="routename"
      ></leisure>
      <utilities
        v-else-if="routename[0] == 'utilities'"
        :data="routename"
      ></utilities>
      <childern
        v-else-if="routename[0] == 'children'"
        :data="routename"
      ></childern>
      <liabilities
        v-else-if="routename[0] == 'liabilities'"
        :data="routename"
      ></liabilities>
      <health
        v-else-if="routename[0] == 'health'"
        :data="routename"
      ></health>
      <cashflow
        v-else-if="routename[0] == 'cashflow'"
        :data="routename"
      ></cashflow>
    </b-col>


    <!-- Content Right -->
    <!-- <b-col
      class="content-header-right d-print-none text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >

        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </template>

        <b-dropdown-item :to="{ name: 'apps-todo' }">
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Todo</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-chat' }">
          <feather-icon
            icon="MessageSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Chat</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-email' }">
          <feather-icon
            icon="MailIcon"
            size="16"
          />
          <span class="align-middle ml-50">Email</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-calendar' }">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <span class="align-middle ml-50">Calendar</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col> -->
  </b-row>
</template>
<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import finance from "./Pages/finance.vue";
// import investment from "./Pages/investment.vue";
import insurance from "./Pages/insurance.vue";
import assets from "./Pages/assets.vue";
import assistance from "./Pages/assistance.vue";
import leisure from "./Pages/leisure.vue";
import utilities from "./Pages/utilities.vue";
import childern from "./Pages/childern.vue";
import liabilities from "./Pages/liabilities.vue";
import health from "./Pages/health.vue";
import cashflow from "./Pages/cashflow.vue";
export default {
  directives: {
    Ripple,
  },
  watch: {
    $route(to, from) {
      this.routename = this.$route.name.split("-") || [];
    },
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    finance,
    // investment,
    insurance,
    assets,
    assistance,
    leisure,
    utilities,
    childern,
    liabilities,
    health,
    cashflow,
  },
  data() {
    return {
      routename: this.$route.name.split("-") || [],
    };
  },
};
</script>
