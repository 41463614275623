<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://userfrp.smartfrp.com" target="_blank"
        >FRP</b-link
      >
      <span class="d-none d-sm-inline-block">, All rights Reserved.</span>
    </span>
    <span class="float-md-right d-none d-md-block" style="font-weight: 900">
      Version 1.70
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
      <!-- <b-link class="ml-25" href="hdttps://userfrp.smartfrp.com" target="_blank">FRP</b-link> -->

      <!-- <b-link class="ml-25" href="htddtp://userfrp2.com" target="_blank">FRP</b-link> -->
