<template>
  <div>
    <div>
      <b-button
        v-for="(item, index) in list"
        :to="{ name: 'leisure-' + item.name }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="data[1] == item.name ? 'primary' : 'flat-primary'"
        :key="'leisuretitle' + index"
      >
        {{ $t(item.title) }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  watch: {
    $route(to, from) {
      this.data = this.$route.name.split("-");
    },
  },

  data() {
    return {
      data: this.$route.name.split("-"),
      list: [

        {
          title: "navigation.subscriptions",
          name: "subscriptions",
        },
        {
          title: "navigation.occasion",
          name: "occasion",
        },
        {
          title: "navigation.membership",
          name: "membership",
        },
        {
          title: "navigation.travel",
          name: "travel",
        },
      ],
    };
  },
};
</script>
