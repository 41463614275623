import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
 
    getalerts(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('settings/alerts', { params: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postalerts(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('settings/alerts', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
