<template>
  <div>
    <div>
      <b-button
        v-for="(item, index) in list"
        :to="{ name: 'cashflow-' + item.name }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="data[1] == item.name ? 'primary' : 'flat-primary'"
        :key="'cashflowtitle' + index"
      >
        {{ $t(item.title) }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  watch: {
    $route(to, from) {
      this.data = this.$route.name.split("-");
    },
  },
  data() {
    return {
      data: this.$route.name.split("-"),
      list: [
        {
          title: "navigation.transaction",
          name: "transaction",
        },
        {
          title: "navigation.transfer",
          name: "transfer",
        },
      ],
    };
  },
};
</script>
