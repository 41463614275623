export default [
  // {
  //   title: 'navigation.dashboards',
  //   icon: 'HomeIcon',
  //   tag: '2',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'navigation.ecommerce',
  //       route: 'dashboard-ecommerce',
  //     },
  //     // {
  //     //   title: 'navigation.analytics',
  //     //   route: 'dashboard-analytics',
  //     // },

  //   ],
  // },

    {
      title: 'navigation.profile',
      icon: 'UserCheckIcon',
      route: 'settings-profile',
    },

  {
    title: 'navigation.dashboards',
    icon: 'EyeIcon',
    route: 'dashboard',
  },

//   {
//     title: 'navigation.transaction',
//     icon: 'TrendingDownIcon',
//     route: 'transaction',
//   },

//   {
//     title: 'navigation.transfer',
//     icon: 'TrendingDownIcon',
//     route: 'transfer',
//   },

]
